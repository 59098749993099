import { Typography } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import React, { useState } from "react";

import styled from "~/components/core/styled";
import { ALL_SUBJECTS } from "~/constants/filters";
import { ISubject } from "~/declarations/models/Subject";
import { isEmpty } from "~/utils/common";

import {
  getGroupIdentifier,
  getGroupSubjects,
  getSelectorState,
  subjectIsGroup
} from "./hierarchyUtils";
import SubjectHierarchyList from "./SubjectHierarchyList";

export interface Props {
  subjects: ISubject[];
  initSelectedSubjects: ISubject[];
  multiselect?: boolean;
  onSelect?: (subjects: ISubject[]) => void;
  onClose: (selectedSubjects: ISubject[]) => void;
  onSelectGroup?: (subjectsGroup: ISubject) => void;
}

const SubjectHierarchyView: React.FC<Props> = ({
  subjects,
  initSelectedSubjects,
  onClose,
  onSelect,
  onSelectGroup,
  multiselect = false
}: Props): JSX.Element => {
  const [selectedSubjects, setSelectedSubjects] = useState<ISubject[]>(
    initSelectedSubjects
  );
  const {
    selectedSubjectsIds,
    partiallySelectedGroupsIdentifiers,
    fullySelectedGroupsIdentifiers
  } = getSelectorState(subjects, selectedSubjects);

  const updateSelected = (newSelectedSubjects: ISubject[]) => {
    setSelectedSubjects(newSelectedSubjects);
    onSelect?.(newSelectedSubjects);
    if (!multiselect) {
      onClose(newSelectedSubjects);
    }
    if (onSelect) {
      onSelect(newSelectedSubjects);
    }
  };

  const handleSubjectSelect = (subject: ISubject) => {
    if (subjectIsGroup(subject)) return;
    let newSelectedSubjects: ISubject[];
    if (multiselect) {
      if (selectedSubjectsIds.includes(subject.id!)) {
        newSelectedSubjects = selectedSubjects.filter(s => s.id !== subject.id);
      } else {
        newSelectedSubjects = [...selectedSubjects, subject];
      }
    } else {
      newSelectedSubjects = [subject];
    }
    updateSelected(newSelectedSubjects);
  };

  const handleGroupClick = (subject: ISubject) => {
    if (!subjectIsGroup(subject)) return;
    if (subject.name === ALL_SUBJECTS) {
      updateSelected([]);
      onSelectGroup?.(subject);
      return;
    }
    if (!activeFirstLevel) {
      setActiveFirstLevel(subject);
    } else if (!activeSecondLevel) {
      setActiveSecondLevel(subject);
    }
  };

  const handleGroupSelect = (subject: ISubject) => {
    if (!subjectIsGroup(subject) || !multiselect) return;
    if (subject.name === ALL_SUBJECTS) {
      updateSelected([]);
      onSelectGroup?.(subject);
      return;
    }
    const groupSubjects = getGroupSubjects(subject);
    const groupSubjectsIds = groupSubjects.map(s => s.id!);
    let newSelectedSubjects: ISubject[];
    if (fullySelectedGroupsIdentifiers.includes(getGroupIdentifier(subject))) {
      newSelectedSubjects = selectedSubjects.filter(
        s => !groupSubjectsIds.includes(s.id!)
      );
    } else {
      newSelectedSubjects = groupSubjects.reduce(
        (res, subject) => {
          return !selectedSubjectsIds.includes(subject.id!)
            ? [...res, subject]
            : res;
        },
        [...selectedSubjects]
      );
    }
    updateSelected(newSelectedSubjects);
  };

  const [
    activeFirstLevel,
    setActiveFirstLevel
  ] = React.useState<ISubject | null>(null);
  const [
    activeSecondLevel,
    setActiveSecondLevel
  ] = React.useState<ISubject | null>(null);

  const handleBackClick = (): void => {
    if (activeSecondLevel) {
      setActiveSecondLevel(null);
    } else if (activeFirstLevel) {
      setActiveFirstLevel(null);
    }
  };

  const handleCloseList = (): void => {
    onClose(selectedSubjects);
  };

  return (
    <ClickAwayListener onClickAway={handleCloseList}>
      <Wrapper elevation={9}>
        {isEmpty(subjects) ? (
          <Placeholder>No data</Placeholder>
        ) : (
          <>
            <Slide direction="right" in={!activeFirstLevel}>
              {/* <SubjectGroupListWrapper> */}
              <SubjectsWrapper>
                <SubjectHierarchyList
                  list={subjects}
                  selectedSubjectsIds={selectedSubjectsIds}
                  partiallySelectedGroupsIdentifiers={
                    partiallySelectedGroupsIdentifiers
                  }
                  fullySelectedGroupsIdentifiers={
                    fullySelectedGroupsIdentifiers
                  }
                  multiselect={multiselect}
                  onBack={handleBackClick}
                  handleGroupClick={handleGroupClick}
                  handleGroupSelect={handleGroupSelect}
                  handleSubjectSelect={handleSubjectSelect}
                />
              </SubjectsWrapper>
            </Slide>

            <Slide
              direction={
                !activeFirstLevel && activeSecondLevel ? "right" : "left"
                // FIXME: needs to remember previous step in order to always slide in proper direction
              }
              in={!!(activeFirstLevel && !activeSecondLevel)}
            >
              <SubjectsWrapper>
                <SubjectHierarchyList
                  list={activeFirstLevel?.children ?? []}
                  backCaption={activeFirstLevel?.name}
                  selectedSubjectsIds={selectedSubjectsIds}
                  partiallySelectedGroupsIdentifiers={
                    partiallySelectedGroupsIdentifiers
                  }
                  fullySelectedGroupsIdentifiers={
                    fullySelectedGroupsIdentifiers
                  }
                  multiselect={multiselect}
                  onBack={handleBackClick}
                  handleGroupClick={handleGroupClick}
                  handleGroupSelect={handleGroupSelect}
                  handleSubjectSelect={handleSubjectSelect}
                />
              </SubjectsWrapper>
            </Slide>

            <Slide direction="left" in={!!activeSecondLevel}>
              <SubjectsWrapper>
                <SubjectHierarchyList
                  list={activeSecondLevel?.children ?? []}
                  backCaption={activeSecondLevel?.name}
                  selectedSubjectsIds={selectedSubjectsIds}
                  partiallySelectedGroupsIdentifiers={
                    partiallySelectedGroupsIdentifiers
                  }
                  fullySelectedGroupsIdentifiers={
                    fullySelectedGroupsIdentifiers
                  }
                  multiselect={multiselect}
                  onBack={handleBackClick}
                  handleGroupClick={handleGroupClick}
                  handleGroupSelect={handleGroupSelect}
                  handleSubjectSelect={handleSubjectSelect}
                />
              </SubjectsWrapper>
            </Slide>
          </>
        )}
      </Wrapper>
    </ClickAwayListener>
  );
};

export default SubjectHierarchyView;

const Wrapper = styled(Paper)`
  border-radius: 4px;
  display: flex;
  max-height: 500px;
  min-height: 300px;
  min-width: 280px;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
`;

const Placeholder = styled(Typography)`
  color: ${({ theme }): string => theme.palette.grey[600]};
  height: 100%;
  margin: auto;
  text-align: center;
  width: 100%;
`;

const SubjectsWrapper = styled.div`
  flex: 1;
  position: absolute;
  width: 100%;
`;
